import {
  ExternalTrackingInfoFragment,
  PackStatus,
  ShipmentFragment,
  TrackingInfoFragment,
} from '@/generated/graphql';
// TODO: Move to a common package
import { parseOptionalPlainTime, parsePlainTime } from '@packfleet/datetime';

export const INTERNAL_TRACKING_NUMBER_REGEX = /([a-z]+-)+[a-z]+/;

export enum ShipmentTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export function formatPackStatusForTracking(
  status: PackStatus,
  externalCarrier?: string,
): string {
  return {
    [PackStatus.Scheduled]: 'Booked for collection',
    [PackStatus.OutForCollection]: 'Out for collection',
    [PackStatus.Collected]: 'Collected from merchant',
    [PackStatus.InDepot]: 'In our depot',
    [PackStatus.ExternalCarrierCollected]: externalCarrier
      ? `Collected by ${externalCarrier}`
      : 'Collected by our delivery partner',
    [PackStatus.ExternalCarrierInTransit]: 'In transit',
    [PackStatus.OutForDelivery]: 'Out for delivery',
    [PackStatus.AvailableForRecipientPickup]: 'Available for pickup',
    [PackStatus.Delivered]: 'Delivered',
    [PackStatus.FailedDelivery]: 'Delivery failed',
    [PackStatus.OnHold]: 'On hold',
    [PackStatus.ExternalCarrierOnHold]: externalCarrier
      ? `On hold by ${externalCarrier}`
      : 'On hold by our delivery partner',
    [PackStatus.ToReturnToSender]: 'Returning to sender',
    [PackStatus.ReturnedToSender]: 'Returned to sender',
    [PackStatus.Undeliverable]: 'Undeliverable',
  }[status];
}

export function isStatusFinal(status: PackStatus): boolean {
  const finalStatuses: PackStatus[] = [
    PackStatus.Delivered,
    PackStatus.ReturnedToSender,
    PackStatus.ToReturnToSender,
  ];
  return finalStatuses.includes(status);
}

const TIME_WINDOW_NOTIFICATION_THRESHOLD_HOURS = 6;

export const shipmentHasTimeWindowLessThanThreshold = (
  shipment: ShipmentFragment,
): boolean => {
  if (shipment.delivery.startTime == null && shipment.delivery.endTime == null)
    return false;

  const startTime =
    parseOptionalPlainTime(shipment.delivery.startTime) ??
    parsePlainTime('08:00');
  const endTime =
    parseOptionalPlainTime(shipment.delivery.endTime) ??
    parsePlainTime('21:00');

  return (
    endTime.since(startTime).hours < TIME_WINDOW_NOTIFICATION_THRESHOLD_HOURS
  );
};

export function isTrackingInfoFragment(
  fragment: TrackingInfoFragment | ExternalTrackingInfoFragment,
): fragment is TrackingInfoFragment {
  return fragment.__typename === 'TrackingInfo';
}

export function isExternalTrackingInfoFragment(
  fragment: TrackingInfoFragment | ExternalTrackingInfoFragment,
): fragment is ExternalTrackingInfoFragment {
  return fragment.__typename === 'ExternalTrackingInfo';
}

export function getShipmentTypeFromTrackingNumber(
  trackingNumber: string,
): ShipmentTypes {
  // Our tracking "numbers" are in a very predictable format, and the data is fairly
  // guaranteed to be structured reliably, so this is not as fragile as it looks at present.
  // This could change in future, however. It would be better to send the whole tracking URL
  // which would give us more to use to distinguish between these two.
  if (trackingNumber.match(INTERNAL_TRACKING_NUMBER_REGEX)) {
    return ShipmentTypes.INTERNAL;
  } else {
    return ShipmentTypes.EXTERNAL;
  }
}
